export const FECTH_BLOG_REQUEST = "FECTH_BLOG_REQUEST";
export const FECTH_BLOG_SUCCESS = "FECTH_BLOG_SUCCESS";
export const FECTH_BLOG_FAILED = "FECTH_BLOG_FAILED";

export const SEARCH_BLOG_REQUEST = "SEARCH_BLOG_REQUEST";
export const SEARCH_BLOG_SUCCESS = "SEARCH_BLOG_SUCCESS";
export const SEARCH_BLOG_FAILED = "SEARCH_BLOG_FAILED";

export const BLOG_DETAILS_REQUEST = "BLOG_DETAILS_REQUEST";
export const BLOG_DETAILS_SUCCESS = "BLOG_DETAILS_SUCCESS";
export const BLOG_DETAILS_FAILED = "BLOG_DETAILS_FAILED";

export const LIKE_BLOG_REQUEST = "LIKE_BLOG_REQUEST";
export const LIKE_BLOG_SUCCESS = "LIKE_BLOG_SUCCESS";
export const LIKE_BLOG_FAILED = "LIKE_BLOG_FAILED";

export const UNLIKE_BLOG_REQUEST = "UNLIKE_BLOG_REQUEST";
export const UNLIKE_BLOG_SUCCESS = "UNLIKE_BLOG_SUCCESS";
export const UNLIKE_BLOG_FAILED = "UNLIKE_BLOG_FAILED";

export const LIKES_COUNT_REQUEST = "LIKES_COUNT_REQUEST";
export const LIKES_COUNT_SUCCESS = "LIKES_COUNT_SUCCESS";
export const LIKES_COUNT_FAILED = "LIKES_COUNT_FAILED";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAILED = "DELETE_BLOG_FAILED";

export const FETCH_GENRE_REQUEST = "FETCH_GENRE_REQUEST";
export const FETCH_GENRE_SUCCESS = "FETCH_GENRE_SUCCESS";
export const FETCH_GENRE_FAILED = "FETCH_GENRE_FAILED";