export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";

export const CHECK_USERNAME_REQUEST = "CHECK_USERNAME_REQUEST";
export const CHECK_USERNAME_SUCCESS = "CHECK_USERNAME_SUCCESS";
export const CHECK_USERNAME_FAILED = "CHECK_USERNAME_FAILED";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";